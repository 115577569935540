import { BrowserOnlyContent } from '@components/browser-only-content';
import { DashboardCard } from '@components/dashboard-card';
import { Headline } from '@components/headline';
import { ROUTES_PATH_CONFIG } from '@config/routes-config';
import { userAuthState } from '@state/user-state';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-export-i18n';
import { NextSeo } from 'next-seo';
import { AiFillRead, AiOutlineFolderAdd } from 'react-icons/ai';
import { BiCalculator } from 'react-icons/bi';
import { GiProgression, GiTeacher } from 'react-icons/gi';
import { ImProfile } from 'react-icons/im';
import { IoMdLogIn } from 'react-icons/io';
import { useRecoilValue } from 'recoil';

import gymTrackerImage from '../images/gym-tracker-with-frame-optimized.png';

import type { ReactNode } from 'react';
import type { IconBaseProps } from 'react-icons';

type WidgetConfig = {
  iconClasses: string;
  icon: (props: IconBaseProps) => ReactNode;
  href: string;
  translationKey: string;
};

const LOGGED_OUT_WIDGETS: WidgetConfig[] = [
  {
    iconClasses: 'text-white bg-green-600',
    icon: IoMdLogIn,
    href: ROUTES_PATH_CONFIG.login,
    translationKey: 'login',
  },
  {
    iconClasses: 'text-white bg-pink-600',
    icon: ImProfile,
    href: ROUTES_PATH_CONFIG.register,
    translationKey: 'register',
  },
  {
    iconClasses: 'text-white bg-gray-600',
    icon: GiTeacher,
    href: ROUTES_PATH_CONFIG.tutorials,
    translationKey: 'tutorials',
  },
  {
    iconClasses: 'text-black bg-white border border-black',
    icon: AiFillRead,
    href: ROUTES_PATH_CONFIG.wiki,
    translationKey: 'wiki',
  },
  {
    iconClasses: 'text-white bg-blue-600',
    icon: BiCalculator,
    href: ROUTES_PATH_CONFIG.tools,
    translationKey: 'tools',
  },
];

const LOGGED_IN_WIDGETS: WidgetConfig[] = [
  {
    iconClasses: 'text-white bg-green-600',
    icon: AiOutlineFolderAdd,
    href: ROUTES_PATH_CONFIG.addSet,
    translationKey: 'addSet',
  },
  {
    iconClasses: 'text-white bg-pink-600',
    icon: GiProgression,
    href: ROUTES_PATH_CONFIG.progress,
    translationKey: 'showProgress',
  },
  {
    iconClasses: 'text-white bg-gray-600',
    icon: GiTeacher,
    href: ROUTES_PATH_CONFIG.tutorials,
    translationKey: 'tutorials',
  },
  {
    iconClasses: 'text-black bg-white border border-black',
    icon: AiFillRead,
    href: ROUTES_PATH_CONFIG.wiki,
    translationKey: 'wiki',
  },
  {
    iconClasses: 'text-white bg-blue-600',
    icon: BiCalculator,
    href: ROUTES_PATH_CONFIG.tools,
    translationKey: 'tools',
  },
  {
    iconClasses: 'text-white bg-yellow-400',
    icon: ImProfile,
    href: ROUTES_PATH_CONFIG.profile,
    translationKey: 'profile',
  },
];

export default function Home() {
  const { t } = useTranslation();
  const isLoggedIn = useRecoilValue<boolean>(userAuthState);

  return (
    <div>
      <NextSeo description={t('metaDescription.frontpage')} />

      <BrowserOnlyContent>
        {isLoggedIn && (
          <>
            <Headline as="h2" text={t('headline.actions')} />
            <div className="grid gap-3 grid-cols-1 md:grid-cols-2 mt-6 md:mt-4 mb-10">
              {LOGGED_IN_WIDGETS.map((widget, index) => (
                <Link key={index} href={widget.href}>
                  <DashboardCard
                    headline={t(`navigation.${widget.translationKey}`)}
                    iconClasses={widget.iconClasses}
                    icon={widget.icon}
                  />
                </Link>
              ))}
            </div>
          </>
        )}
      </BrowserOnlyContent>

      <div className="flex flex-col md:flex-col">
        <div className="max-w-screen-lg mx-auto container flex justify-center flex-col md:flex-row items-center md:my-12">
          <div className="flex flex-col w-full lg:w-1/2 justify-center items-start p-6 pt-0 md:p-6">
            <h1 className="uppercase font-bold">GYM TRACKER</h1>
            <div className="font-bold text-3xl my-4">Tracke Deine Gym-Fortschritte</div>
            <div className="leading-normal mb-4">
              Du willst Deinen Progress im Gym tracken? Perfekt, denn genau diesen Zweck erfüllt der Gym-Tracker! Hier
              kannst Du all Deine Fortschritte verfolgen und Erfolge visualisieren.
            </div>
          </div>
          <div className="w-full lg:w-1/2 lg:py-6 justify-center hidden lg:flex">
            <Image
              src={gymTrackerImage.src}
              alt="Gym-Tracker.de homepage on mobile device."
              width={200}
              height={399}
              fetchPriority="high"
              loading="eager"
            />
          </div>
        </div>

        <BrowserOnlyContent>
          {!isLoggedIn && (
            <div className="grid gap-3 grid-cols-1 md:grid-cols-3 md:mt-4 mb-0">
              {LOGGED_OUT_WIDGETS.map((widget, index) => (
                <Link key={index} href={widget.href}>
                  <DashboardCard
                    headline={t(`navigation.${widget.translationKey}`)}
                    iconClasses={widget.iconClasses}
                    icon={widget.icon}
                  />
                </Link>
              ))}
            </div>
          )}
        </BrowserOnlyContent>
      </div>
    </div>
  );
}
