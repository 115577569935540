import type { ReactNode } from 'react';
import type { IconBaseProps } from 'react-icons';

export type DashboardCardProps = {
  headline: string;
  iconClasses: string;
  icon: (props: IconBaseProps) => ReactNode;
};

export function DashboardCard({ headline, iconClasses, icon }: DashboardCardProps) {
  return (
    <div className={`bg-white hover:bg-gray-50 rounded shadow p-2`}>
      <div className="flex flex-row items-center">
        <div className="flex-shrink pr-4">
          <div className={`rounded p-3 ${iconClasses}`}>{icon({ className: 'w-8 h-8' })}</div>
        </div>
        <div className="flex-1 text-left md:text-center">
          <h2 className="font-bold text-l">{headline}</h2>
        </div>
      </div>
    </div>
  );
}
